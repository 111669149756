<template>
  <div id="mian">
    <div class="header">
      <span @click="toUrl('ledger_addjbxx')">基本信息</span>
      <span @click="toUrl('ledger_addglqd')">关联渠道</span>
      <span id="fzcolor">补充材料</span>
    </div>
    <div class="desc">
      <h5 class="jsxx">补充材料(注：至少上传一组)</h5>
    </div>
    <div class="bcclBox">
        <div class="head">
          <div class="line1">材料种类</div>
          <div class="line2">材料类型</div>
          <div class="line3">材料内容</div>
        </div>
        <div class="item">
          <div class="line1">店铺信息</div>
          <div class="line2">
            <div>店铺认证截图</div>
            <div>店铺货物截图</div>
            <div>支付流程截图</div>
            <div>店铺结算记录截图</div>
            <div>店铺链接</div>
          </div>
          <div class="line3">
            <div>
              <el-upload
                class="avatar-uploader"
                :before-upload="beforeUpload"
                drag
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="storeAuthPicSuccess"
              >
                <img
                  v-if="formData.storeAuthPic"
                  :src="formData.storeAuthPic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span v-if="formData.storeAuthPic" class="avatar-uploader-span" @click.stop="formData.storeAuthPic = ''"><i class="el-icon-close"></i></span>
              </el-upload>
            </div>
            <div>
              <el-upload 
                class="avatar-uploader"
                :before-upload="beforeUpload"
                drag
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="storeComPicSuccess"
              >
                <img
                  v-if="formData.storeComPic"
                  :src="formData.storeComPic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span v-if="formData.storeComPic" class="avatar-uploader-span" @click.stop="formData.storeComPic=''"><i class="el-icon-close"></i></span>
              </el-upload>
            </div>
            <div>
              <el-upload 
                class="avatar-uploader"
                :before-upload="beforeUpload"
                drag
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="storePayPicSuccess"
              >
                <img
                  v-if="formData.storePayPic"
                  :src="formData.storePayPic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span v-if="formData.storePayPic" class="avatar-uploader-span" @click.stop="formData.storePayPic=''"><i class="el-icon-close"></i></span>
              </el-upload>
            </div>
            <div>
              <el-upload 
                class="avatar-uploader"
                :before-upload="beforeUpload"
                drag
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="storeSetPicSuccess"
              >
                <img
                  v-if="formData.storeSetPic"
                  :src="formData.storeSetPic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span v-if="formData.storeSetPic" class="avatar-uploader-span" @click.stop="formData.storeSetPic=''"><i class="el-icon-close"></i></span>
              </el-upload>              
            </div>
            <div>
              <el-input v-model="formData.storeUrl" class="inputs" placeholder="输入店铺链接"></el-input>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="line1">APP信息</div>
          <div class="line2">
            <div>支付流程截图</div>
            <div>首页货品截图</div>
            <div>认证页截图</div>
            <div>APP 名称</div>
            <div>APP 账号</div>
            <div>APP 密码</div>
          </div>
          <div class="line3">
            <div>
              <el-upload 
                class="avatar-uploader"
                :before-upload="beforeUpload"
                drag
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="appPayPicSuccess"
              >
                <img
                  v-if="formData.appPayPic"
                  :src="formData.appPayPic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span v-if="formData.appPayPic" class="avatar-uploader-span" @click.stop="formData.appPayPic=''"><i class="el-icon-close"></i></span>
              </el-upload>
            </div>
            <div>
              <el-upload 
                class="avatar-uploader"
                :before-upload="beforeUpload"
                drag
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="appComPicSuccess"
              >
                <img
                  v-if="formData.appComPic"
                  :src="formData.appComPic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span v-if="formData.appComPic" class="avatar-uploader-span" @click.stop="formData.appComPic=''"><i class="el-icon-close"></i></span>
              </el-upload>              
            </div>
            <div>
              <el-upload 
                class="avatar-uploader"
                :before-upload="beforeUpload"
                drag
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="appAuthPicSuccess"
              >
                <img
                  v-if="formData.appAuthPic"
                  :src="formData.appAuthPic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span v-if="formData.appAuthPic" class="avatar-uploader-span" @click.stop="formData.appAuthPic=''"><i class="el-icon-close"></i></span>
              </el-upload>              
            </div>
            <div>
              <el-input v-model="formData.appName" class="inputs" placeholder="输入APP名称"></el-input>
            </div>
            <div>
              <el-input v-model="formData.appAccount" class="inputs" placeholder="输入APP账号"></el-input>
            </div>
            <div>
              <el-input v-model="formData.appPasswordAPP" class="inputs" placeholder="输入APP密码"></el-input>
            </div>
          </div>          
        </div>
        <div class="item">
          <div class="line1">公众号信息</div>
          <div class="line2">
            <div>支付流程截图</div>
            <div>首页货品截图</div>
            <div>认证页截图</div>
            <div>公众号名称</div>
          </div>
          <div class="line3">
            <div>
              <el-upload 
                class="avatar-uploader"
                :before-upload="beforeUpload"
                drag
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="mpPayPicSuccess"
              >
                <img
                  v-if="formData.mpPayPic"
                  :src="formData.mpPayPic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span v-if="formData.mpPayPic" class="avatar-uploader-span" @click.stop="formData.mpPayPic=''"><i class="el-icon-close"></i></span>
              </el-upload>              
            </div>
            <div>
              <el-upload 
                class="avatar-uploader"
                :before-upload="beforeUpload"
                drag
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="mpComPicSuccess"
              >
                <img
                  v-if="formData.mpComPic"
                  :src="formData.mpComPic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span v-if="formData.mpComPic" class="avatar-uploader-span" @click.stop="formData.mpComPic=''"><i class="el-icon-close"></i></span>
              </el-upload>               
            </div>
            <div>
              <el-upload 
                class="avatar-uploader"
                :before-upload="beforeUpload"
                drag
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="mpAuthPicSuccess"
              >
                <img
                  v-if="formData.mpAuthPic"
                  :src="formData.mpAuthPic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span v-if="formData.mpAuthPic" class="avatar-uploader-span" @click.stop="formData.mpAuthPic=''"><i class="el-icon-close"></i></span>
              </el-upload>               
            </div>
            <div>
              <el-input v-model="formData.mpName" class="inputs" placeholder="输入公众号名称"></el-input>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="line1">H5 信息</div>
          <div class="line2">
            <div>支付流程截图</div>
            <div>首页货品截图</div>
            <div>H5 链接</div>
            <div>H5 账号</div>
            <div>H5 密码</div>
          </div>
          <div class="line3">
            <div>
              <el-upload 
                class="avatar-uploader"
                :before-upload="beforeUpload"
                drag
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="h5PayPicSuccess"
              >
                <img
                  v-if="formData.h5PayPic"
                  :src="formData.h5PayPic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span v-if="formData.h5PayPic" class="avatar-uploader-span" @click.stop="formData.h5PayPic=''"><i class="el-icon-close"></i></span>
              </el-upload>
            </div>
            <div>
              <el-upload 
                class="avatar-uploader"
                :before-upload="beforeUpload"
                drag
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="h5ComPicSuccess"
              >
                <img
                  v-if="formData.h5ComPic"
                  :src="formData.h5ComPic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span v-if="formData.h5ComPic" class="avatar-uploader-span" @click.stop="formData.h5ComPic=''"><i class="el-icon-close"></i></span>
              </el-upload>
            </div>
            <div>
              <el-input v-model="formData.h5Url" class="inputs" placeholder="输入H5链接"></el-input>
            </div>
            <div>
              <el-input v-model="formData.h5Account" class="inputs" placeholder="输入H5账号"></el-input>
            </div>
            <div>
              <el-input v-model="formData.h5Password" class="inputs" placeholder="输入H5密码"></el-input>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="line1">小程序信息</div>
          <div class="line2">
            <div>支付流程截图</div>
            <div>首页货品截图</div>
            <div>认证页截图</div>
            <div>小程序名称</div>
          </div>
          <div class="line3">
            <div>
              <el-upload 
                class="avatar-uploader"
                :before-upload="beforeUpload"
                drag
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="miniPayPicSuccess"
              >
                <img
                  v-if="formData.miniPayPic"
                  :src="formData.miniPayPic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span v-if="formData.miniPayPic" class="avatar-uploader-span" @click.stop="formData.miniPayPic=''"><i class="el-icon-close"></i></span>
              </el-upload>
            </div>
            <div>
              <el-upload 
                class="avatar-uploader"
                :before-upload="beforeUpload"
                drag
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="miniComPicSuccess"
              >
                <img
                  v-if="formData.miniComPic"
                  :src="formData.miniComPic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span v-if="formData.miniComPic" class="avatar-uploader-span" @click.stop="formData.miniComPic=''"><i class="el-icon-close"></i></span>
              </el-upload>
            </div>
            <div>
              <el-upload 
                class="avatar-uploader"
                :before-upload="beforeUpload"
                drag
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="miniAuthPicSuccess"
              >
                <img
                  v-if="formData.miniAuthPic"
                  :src="formData.miniAuthPic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span v-if="formData.miniAuthPic" class="avatar-uploader-span" @click.stop="formData.miniAuthPic=''"><i class="el-icon-close"></i></span>
              </el-upload>
            </div>
            <div>
              <el-input v-model="formData.miniName" class="inputs" placeholder="输入小程序名称"></el-input>
            </div>
          </div>
        </div>
        <div class="item">
          <div class="line1">代运营公司合作证明</div>
          <div class="line2">
            <div>代运营公司合作协议或合作说明</div>
          </div>
          <div class="line3">
            <div>
              <el-upload 
                class="avatar-uploader"
                :before-upload="beforeUploadPDF"
                drag
                :action="uploadUrl"
                :show-file-list="false"
                :on-success="businessContractSuccess"
                style="width: 94%;"
              >
                <!-- <img  v-if="formData.businessContract"  :src="formData.businessContract"  class="avatar" /> -->
                <div v-if="formData.businessContract" class="avatar" style="width: 100%;display: flex;align-items: center;padding: 0px 4px;box-sizing: border-box;text-align: left;">{{ formData.businessContract }}</div>
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <span v-if="formData.businessContract" class="avatar-uploader-span" @click.stop="formData.businessContract=''"><i class="el-icon-close"></i></span>
              </el-upload>
            </div>
          </div>
        </div>
    </div>
    <div class="btn">
      <el-button @click="toUrl('ledger_addglqd', false)" class="back" >上一步</el-button>
      <el-button @click="submitBTN()" class="next" type="primary">确定</el-button>
    </div>
  </div>
</template>
<script>
import {addledger} from "@/api/user/shopManager.js"; 
import { URL_API } from "@/utils/apiUrl.js";
import { mapState, mapMutations } from "vuex";
import * as imageConversion from "image-conversion";
import md5 from "js-md5";
export default {
  data() {
    return {
      isImge: true, 
      mchDetail:"",
      formData:{
        storeAuthPic:"",//店铺认证截图 //店铺信息
        storeComPic:"",//店铺货物截图
        storePayPic:"",//支付流程截图
        storeSetPic:"",//店铺结算记录截图
        storeUrl:"",//店铺链接  
        appPayPic:"",//支付流程截图 //APP信息
        appComPic:"",//首页货品截图
        appAuthPic:"",//认证页截图
        appName:"",//APP名称
        appAccount:"",//APP账号
        appPasswordAPP:"",//APP密码
        mpPayPic:"",//支付流程截图 //公众号信息
        mpComPic:"",//首页货品截图
        mpAuthPic:"",//认证页截图
        mpName:"",//公众号名称
        h5PayPic:"",//支付流程截图 //H5 信息
        h5ComPic:"",//首页货品截图
        h5Url:"",//H5 链接
        h5Account:"",//H5 账号
        h5Password:"",//H5 密码 
        miniPayPic:"",//支付流程截图 //小程序信息
        miniComPic:"",//首页货品截图
        miniAuthPic:"",//认证页截图
        miniName:"",//小程序名称  
        businessContract:"",//代运营公司合作协议或合作说明  //代运营公司合作证明
      },
      uploadUrl: URL_API.upload,
      md5,
    };
  },
  computed: {
    ...mapState({
      AddLedger: "user_addLedger",
    }),
  },
  mounted() {
    if (this.AddLedger) {
      this.mchDetail= this.AddLedger.mchDetail ? this.AddLedger.mchDetail : [];
      this.formData = this.AddLedger.mchProfileSupplementPO ? this.AddLedger.mchProfileSupplementPO : this.formData;
    }
  },
  methods: {
    ...mapMutations({
      setAddLedger: "user_setAddLedger",
    }),
    submitBTN(){
        if(this.formData.storeAuthPic && this.formData.storeComPic && this.formData.storePayPic && this.formData.storeSetPic && this.formData.storeUrl){         
        }else if(this.formData.appPayPic && this.formData.appComPic && this.formData.appAuthPic && this.formData.appName && this.formData.appAccount && this.formData.appPasswordAPP ){         
        }else if(this.formData.mpPayPic && this.formData.mpComPic && this.formData.mpAuthPic && this.formData.mpName ){          
        }else if(this.formData.h5PayPic && this.formData.h5ComPic && this.formData.h5Url && this.formData.h5Account && this.formData.h5Password){       
        }else if(this.formData.miniPayPic && this.formData.miniComPic && this.formData.miniAuthPic && this.formData.miniName){         
        }else if(this.formData.businessContract){         
        }else{
          this.$message.error("补充材料至少上传一组");
          return false;
        }

        let bcclDataPsot = {...this.formData};
        let tabDatas = {
          mchDetail: this.AddLedger ? this.AddLedger.mchDetail : null,  
          settlements: this.AddLedger ? this.AddLedger.settlements : null,       
          mchProfileSupplementPO: bcclDataPsot,
        };
      
      this.setAddLedger(tabDatas);

      let mchDetail = JSON.parse(JSON.stringify(this.AddLedger.mchDetail));
          mchDetail.merchantId=this.AddLedger.settlements[0].merchantId;
          mchDetail.allocMchId=this.AddLedger.settlements[0].allocMchId;
          mchDetail.allocMchNo=this.AddLedger.settlements[0].allocMchNo;

      let data = {
        mchDetail: this.AddLedger ? mchDetail: null,
        mchProfileSupplementPO: bcclDataPsot,
      };

      console.log("提交的数据",data)

      addledger(data).then((res) => {
        if (res.resultStatus) {
          this.setAddLedger(null);
          this.$router.push({
            name: "ledger_index",
          });
        }
      });
    }, 
    //代运营公司合作协议或合作说明
    businessContractSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.businessContract = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    //小程序信息
    miniPayPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.miniPayPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    miniComPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.miniComPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    miniAuthPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.miniAuthPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    //H5 信息
    h5PayPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.h5PayPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    h5ComPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.h5ComPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    //公众号信息
    mpPayPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.mpPayPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    mpComPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.mpComPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    mpAuthPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.mpAuthPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    //APP信息
    appPayPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.appPayPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    appComPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.appComPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    appAuthPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.appAuthPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    // 店铺信息
    storeAuthPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.storeAuthPic = res.resultData.fileLink;
      } else {
        return false;
      }
    },
    storeComPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.storeComPic = res.resultData.fileLink;
      } else {
        return false;
      }     
    },
    storePayPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.storePayPic = res.resultData.fileLink;
      } else {
        return false;
      }     
    },
    storeSetPicSuccess(res) {
      if (this.isImge && res.resultStatus) {
        this.formData.storeSetPic = res.resultData.fileLink;
      } else {
        return false;
      }     
    },
   
    // 页面跳转
    toUrl(name, bool = true) {
      if (bool) {
        if(this.formData.storeAuthPic && this.formData.storeComPic && this.formData.storePayPic && this.formData.storeSetPic && this.formData.storeUrl){         
        }else if(this.formData.appPayPic && this.formData.appComPic && this.formData.appAuthPic && this.formData.appName && this.formData.appAccount && this.formData.appPasswordAPP ){         
        }else if(this.formData.mpPayPic && this.formData.mpComPic && this.formData.mpAuthPic && this.formData.mpName ){          
        }else if(this.formData.h5PayPic && this.formData.h5ComPic && this.formData.h5Url && this.formData.h5Account && this.formData.h5Password){       
        }else if(this.formData.miniPayPic && this.formData.miniComPic && this.formData.miniAuthPic && this.formData.miniName){         
        }else if(this.formData.businessContract){         
        }else{
          this.$message.error("补充材料至少上传一组");
          return false;
        }
      }
      this.$router.push({
        name: name,
      });
    },
    beforeUpload(file) {
      this.isImge = true;
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "jpg" ||
        testmsg === "JPG" ||
        testmsg === "jpeg" ||
        testmsg === "JPEG" ||
        testmsg === "png" ||
        testmsg === "PNG" ||
        testmsg === "bpm" ||
        testmsg === "BPM";
      if (!extension) {
        this.$message.error("上传图片仅支持jpg/jpeg/png/bpm格式");
        this.isImge = false;
        return;
      }
      console.log(file.size,"压缩前")
      return new Promise((resolve) => {
        let isLt2M = file.size / 1024 < 2048; // 判定图片大小是否小于2MB = 2048kb
        if (isLt2M) {
          resolve(file);
        }
        // 压缩到2048KB,这里的2048就是要压缩的大小,可自定义
        imageConversion.compressAccurately(file, 2048).then((res) => {
          console.log(res,"压缩后")
          resolve(res);
        });
       
      });
    },
    beforeUploadPDF(file) {
      this.isImge = true;
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension =
        testmsg === "pdf";
      if (!extension) {
        this.$message.error("上传图片仅支持pdf格式");
        this.isImge = false;
        return;
      }
      console.log(file.name,"压缩前")
      return new Promise((resolve) => {
          resolve(file);
      });
    }, 

  },
  beforeDestroy() {
    let data = {
      mchDetail: this.AddLedger ? this.AddLedger.mchDetail : null,
      settlements: this.AddLedger ? this.AddLedger.settlements : null,
      mchProfileSupplementPO: this.formData,
    };
    this.setAddLedger(data);
  },
};
</script>
<style scoped>
@import "../../../assets/css/desc.css";
.bcclBox{
  border: 1px solid #D7DAE2;
  border-bottom: none;
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  margin-top: 20px;
}
.bcclBox .head , .bcclBox .item{
  display: flex;
  align-items: center;
  border-bottom: 1px solid #D7DAE2;
  text-align: center;  
}
.bcclBox .head>div{
  height: 60px;
  line-height: 60px;
}
.bcclBox .head .line1 , .bcclBox .item .line1{
  width: 200px;
}
.bcclBox .head .line2 , .bcclBox .item .line2{
  width: 240px;
  border-right: 1px solid #D7DAE2;
  border-left: 1px solid #D7DAE2;
}
.bcclBox .head .line3 , .bcclBox .item .line3{
  width: calc(100% - 440px);
}
.bcclBox .item .line2>div , .bcclBox .item .line3>div{
  height: 80px;
  /* line-height: 80px; */
  border-bottom: 1px solid #D7DAE2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bcclBox .item .line3>div .inputs {
  width: 94%;
}

.bcclBox .item .line2>div:last-child , .bcclBox .item .line3>div:last-child{
  border-bottom: none;
}

.upload_wj {
  margin-bottom: 25px;
  width: 100%;
  padding-bottom: 25px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
}
.upload_wj_left {
  float: left;
  margin-left: 44px;
  width: 22%;
  height: 100%;
}
.upload_wj_left i {
  color: var(--themeColor);
  padding-right: 3px;
}
.upload_wj_right {
  float: left;
  margin-left: 200px;
  width: 22%;
  height: 100%;
}

.upload_wj h5 {
  padding: 29px 0 19px 0;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
}

.upload_ph {
  width: 100%;
  height: 200px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(239, 239, 239, 1);
  border-radius: 4px;
  margin-bottom: 20px;
}


.upload_ph p {
  padding-top: 20px;
  padding-bottom: 20px;
}
.upload_ph p span {
  font-size: 14px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}
.upload_ph p span i {
  color: var(--themeColor);
  padding-right: 3px;
}

.uploader1 {
  margin-left: 30px;
  float: left;
}

.uploader1 h4 {
  height: 52px;
  line-height: 52px;
  font-size: 14px;
  color: #000000;
}

.uploader1 h4 i {
  color: var(--themeColor);
  padding-right: 3px;
}

.avatar-uploader {
  width: 60px;
  height: 60px;
  background: rgba(246, 246, 246, 1);
  border: 1px solid rgb(241, 242, 244);
  border-radius: 4px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin: auto;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

.avatar {
  width: 60px;
  height: 60px;
  display: block;
  margin: auto;
}
.cover_view {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.cover_view img {
  position: absolute;
  min-height: 200px;
  min-width: 200px;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
}
.tab1{
  min-height: auto !important;
}
.next {
  width: 120px;
  height: 32px;
  padding: 0;
  background: var(--themeColor);
  border-color: var(--themeColor);
  border-radius: 3px;
  line-height: 10px;
  margin-left: 10px;
  font-size: 12px;
}

.back {
  width: 120px;
  line-height: 10px;
  height: 32px;
  padding: 0;
  background: rgba(255, 255, 255, 1);
  border-color: #dce0e6;
  border: 1px solid rgba(220, 224, 230, 1);
  border-radius: 3px;
  color: var(--title);
  font-size: 12px;
}

.btn {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  height: 33px;
}
.addBt {
  width: 60px;
  height: 32px;
  background-color: var(--themeColor);
  border-color: var(--themeColor);
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: var(--title);
  font-size: 12px;
  padding: 0;
}

.overAccount_ul {
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: var(--label);
}

.overAccount_ul_title i {
  color: var(--themeColor);
  padding-right: 3px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.ul_left {
  float: left;
  width: 200px;
  margin-left: 24px;
}

.ul-right {
  float: right;
  width: 200px;
  margin-right: 26px;
}
</style>